import { loginRequest } from "../authConfig";
import AuthComponent from "../componentWithAuthContext";
import { Context } from "./context/context";


export default class AfterRedirect extends AuthComponent {
    componentWillMount(){
        if(localStorage.getItem("notech_email") == null && localStorage.getItem("no_tech_signintype") == "redirect"){
            this.context.instance.acquireTokenSilent({
                ...loginRequest,
                account: this.context.accounts[0]
            }).then((response) => {
                Context.handleSignIn(response);
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}